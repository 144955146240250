<template>
  <div class="channel-details">
    <van-nav-bar
      fixed
      :title="detailsinfo.customerName"
      left-arrow
      @click-left="$router.go(-1)">
      <!-- <span class="edit" slot="right" @click="edit">Edit</span> -->
    </van-nav-bar>
    <div class="nav-bar"></div>
    <van-tabs v-model="tabsActive">
      <van-tab title="Basic info.">
        <div class="img-content" v-if="detailsinfo.imgUrl">
          <img v-for="(imgItem, imgIndex) in detailsinfo.imgUrl.split(',')" :key="imgIndex" class="img-item" :src="imgItem" alt="" @click.stop="imgPreview(detailsinfo.imgUrl.split(','))">
        </div>
        <div v-else class="img-content">
          <img class="img-item" src="@/assets/images/icon_noimg.png" alt="">
        </div>
        <div class="channel-info">
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Address</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.address }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Country</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.nation }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">State/Province</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.province }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">City</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.city }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Postal Code</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.zipCode }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Store Name</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.customerName }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Store Type</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.customerType }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Store Level</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.customerLevel }}</div>
          </div>
        </div>
      </van-tab>
      <van-tab title="Contact">
        <div class="channel-info channel-contact-info">
          <div v-for="(contactItem, contactIndex) in detailsinfo.contacts" :key="contactIndex" class="channel-item channel-item-less">
            <div>Contact{{ contactIndex + 1 }}：</div>
            <div class="item-info">
              <div class="label-name txt-ellipsis">Title</div>
              <div class="label-value txt-ellipsis">{{ contactItem.title }}</div>
            </div>
            <div class="item-info">
              <div class="label-name txt-ellipsis">Name</div>
              <div class="label-value txt-ellipsis">{{ contactItem.contactName }}</div>
            </div>
            <div class="item-info">
              <div class="label-name txt-ellipsis">Cell Phone Number</div>
              <div class="label-value txt-ellipsis">{{ contactItem.phone }}</div>
            </div>
            <div class="item-info">
              <div class="label-name txt-ellipsis">Race</div>
              <div class="label-value txt-ellipsis">{{ contactItem.race }}</div>
            </div>
            <div class="item-info">
              <div class="label-name txt-ellipsis">E-Mail</div>
              <div class="label-value txt-ellipsis">{{ contactItem.email }}</div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="Business info.">
        <div class="channel-info channel-business-info">
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Disposable brand</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.closeBrand }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Hardware brand</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.openBrand }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Member system</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.memberSystem == 1 ? 'Yes' : 'No' }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Chain or not</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.isChain == 1 ? 'Yes' : 'No' }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Purchase time</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.restockDay }}</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Store Size</div>
            <div class="label-value txt-ellipsis" v-show="detailsinfo.acreage">{{ detailsinfo.acreage }}/ft²</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Single Day Traffic</div>
            <div class="label-value txt-ellipsis" v-show="detailsinfo.dailyFlow">{{ detailsinfo.dailyFlow }}/p</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Daily sales of disposable</div>
            <div class="label-value txt-ellipsis" v-show="detailsinfo.dayOnceCount">{{ detailsinfo.dayOnceCount }}/pcs</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Daily sales percentage of disposable</div>
            <div class="label-value txt-ellipsis" v-show="detailsinfo.onceAmountRate">{{ detailsinfo.onceAmountRate }}/$</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Percentage of disposable display</div>
            <div class="label-value txt-ellipsis" v-show="detailsinfo.onceDisplayRate">{{ detailsinfo.onceDisplayRate }}/%</div>
          </div>
          <div class="channel-item">
            <div class="label-name txt-ellipsis">Supplier</div>
            <div class="label-value txt-ellipsis">{{ detailsinfo.wholesale }}</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="btn-control">
      <van-button class="update-btn" type="primary" block  @click="edit">Update store info</van-button>
      <van-button class="visit-btn" type="info" block @click="toVisit">Visit</van-button>
    </div>
  </div>
</template>

<script>
import { carsCustomerAppInfoApi } from '@/api/home'
import { Toast, ImagePreview } from 'vant'
export default {
  data() {
    return {
      detailsinfo: {},
      paramsUrl: {},
      tabsActive: 0
    }
  },
  created () {},
  async activated () {
    this.paramsUrl = this.$route.query
    if (this.paramsUrl.tabIndex) {
      this.tabsActive = Number(this.paramsUrl.tabIndex)
    }
    this.getDetailsData()
  },
  methods: {
    getDetailsData() {
      Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
      })
      carsCustomerAppInfoApi(this.$route.query.art_id).then(res => {
        Toast.clear()
        console.log('carsCustomerAppInfoApi', res)
        // res.data.contacts
        let contactsLen = res.data.contacts.length
        if (contactsLen < 3) {
					for (let index = 0; index < 3 - contactsLen; index++) {
						// { id: '', label: 'Contact3', contactName: '', phone: '', race: '', email: '', customerId: '' },
						res.data.contacts.push({ id: '', label: `Contact${contactsLen + 1 + index}`, title: '', contactName: '', phone: '', race: '', email: '', customerId: '' })
					}
				}
        this.detailsinfo = res.data
      }).catch(() => {
        Toast.clear()
      })
    },
    toVisit () {
      this.$router.push(`/visitChannelCreate?art_id=${this.paramsUrl.art_id}&projectId=${this.paramsUrl.projectId}`)
    },
    imgPreview(url) {
      ImagePreview(url)
    },
    edit () {
      this.$router.push(`/createStore?art_id=${this.paramsUrl.art_id}&projectId=${this.paramsUrl.projectId}&tabIndex=${this.tabsActive}`)
    }
  }
}
</script>

<style lang="less" scoped>
.channel-details {
  padding-bottom: 20px;
  .edit {
    color: #fff;
  }
  .nav-bar {
    margin-top: 46px;
  } 
  .img-content {
    display: flex;
    height: 100px;
    padding: 10px;
    margin-bottom: 30px;
    overflow: auto;
    .img-item {
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin-right: 10px;
    }
  }
  .channel-info {
    margin-bottom: 30px;
    .channel-item {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      font-size: 12px;
      border-bottom: 1px solid #dce5f6;
      .label-name {
        width:100px;
      }
      .label-value {
        flex: 1;
        text-align: right;
      }
    }
    .channel-item-less {
      display: block;
      .item-info {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .label-name {
          width: 80px;
        }
        .label-value {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
  .channel-contact-info {
    .channel-item {
      .label-name {
        width: 120px !important;
      }
    }
  }
  .channel-business-info {
    .channel-item {
      .label-name {
        width: 200px !important;
      }
    }
  }
  .btn-control {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    width: 100%;
    .update-btn {
      width: 140px;
    }
    .visit-btn {
      width: 230px;
    }
  }
}
</style>
